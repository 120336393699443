import { configFile } from "@/config";
import { Box, Button, Collapse, Flex, useDisclosure } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useState } from "react";

function DesktopNav({ menu }: { menu: any }) {

  const HeaderModal = dynamic(() => import("@/components/HeaderModal"), {
    ssr: false
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [menuId, setMenuId] = useState<number | undefined>()

  const handleMouseOver = (index: number) => {
    setMenuId(index)
    onOpen()
  }

  const handleMouseOut = () => {
    setMenuId(undefined)
    onClose()
  }

  return (
    <>
      <Flex
        alignItems={'flex-start'}
        onMouseLeave={() => handleMouseOut()}
        display={{ base: 'none', lg: 'flex' }}
      >
        {menu.map((item: any, index: number) => (
          <Box key={index} >
            <Button
              aria-label={`menu item ${item.name}`}
              as={Link}
              href={item.url}
              py="27px"
              h='100%'
              fontSize="md"
              lineHeight="6"
              fontWeight="semibold"
              color={index === menuId ? configFile.primaryAccentColor : "#1A202C"}
              borderRadius={'0'}
              bg={'white'}
              cursor={"pointer"}
              borderBottom={`2px solid ${index === menuId ? configFile.primaryAccentColor : 'transparent'}`}
              onMouseEnter={() => handleMouseOver(index)}
              _hover={{ bgColor: 'none', color: configFile.primaryAccentColor, borderColor: configFile.primaryAccentColor }}
              _expanded={{ color: configFile.primaryAccentColor, borderColor: configFile.primaryAccentColor }}
            >
              {item.name}
            </Button>
          </Box>)
        )}
        <Collapse in={isOpen} animateOpacity>
          <Box
            position={"absolute"}
            left={"0"}
            right={"0"}
            margin="0 auto"
            w='100%'
            backgroundColor={"#FFFFFF"}
            mt={'80px'}
            p={'1.5rem'}
            boxShadow={"xl"}
            borderBottomRadius={"12px"}
            height="23.5rem"
          >
            {
              menuId !== undefined &&
              <HeaderModal url={menu[menuId].url} articles={menu[menuId].articles.data} />
            }
          </Box>
        </Collapse>
      </Flex>
    </>
  );
}

export default DesktopNav;